
<template>
 <div>  
    <div>
      <div>
        <h1 class="text-3xl font-semibold tracking-wide">Unit Shortlists</h1>
        <h3 
        style="
        padding-bottom: 10px;
        padding-top: 10px;
        ">
        User details for unit {{document.name}}</h3>
         <div class="border-b">
        <router-link
          :to="{ path: 'units-shortlisted' }"        
        >
        <button
          class="lb-dialog-close-btn-x"
        >
          ×
        </button>
        </router-link>
    </div>
      </div>
    </div>
    <!-- Unit Shortlisters Table Content -->
    <table
      class="min-w-max w-full mt-5 table-auto paginated table-fixed" style="padding: 10px"
      :sticky-header="true"
      :data="unitShortlisters"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      :loading="loading"
      :mobile-cards="true"
      :paginated="true"
      :per-page="10"
    >
      <!-- Users Table Content Header -->
      <thead>
      <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
        <th
          @click="sort('firstName')"
          class="heading-text"
          :class="{ sorting: currentSort === 'firstName' }"
        >
          First Name
          <MenuDown
            v-if="isSorted('firstName', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
          v-if="isSorted('firstName', 'asc')"
          class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('lastName')"
          class="heading-text"
          :class="{ sorting: currentSort === 'lastName' }"
        >
          Last Name
          <MenuDown
            v-if="isSorted('lastName', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('lastName', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('email')"
          class="heading-text tableColumnWidth"
          :class="{ sorting: currentSort === 'email' }"
        >
          Email
          <MenuDown
            v-if="isSorted('email', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('email', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          class="heading-text"
        >
          Contact Number
        </th>
      </tr>
      </thead>
      <!-- Users Table Content Body -->
      <tbody class="text-gray-600 text-sm font-light">
      <tr
        v-for="user in sortUsers"
        :key="user.id"
        class="border-b border-gray-200 hover:bg-gray-100"
      >
        <td
          class="py-3 px-6 text-center"
        >
          {{ user.firstName }}
        </td>
        <td
          class="py-3 px-6 text-center"
        >
          {{ user.lastName }}
        </td>
        <td
          class="py-3 px-6 text-center tableColumnWidth"
        >
          <a :href="`mailto:${user.email}`">
            {{ user.email }}
          </a>
        </td>
        <td
          class="py-3 px-3 text-center"
        >
          <a :href="`tel:${user.e164ContactNumber}`">
            {{user.formatNationalContactNumber }}
          </a>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="closeButtonPadding">
    <button
      class="ml-5  bg-primary px-8 py-2 text-white font-semibold rounded"
    >
    <router-link
     
      :to="{ path: 'units-shortlisted' }"        
    >
        <div>Close</div>
    </router-link>
    </button>
    </div>
</div>
</template>
<script>
import {unitsCollection, profilesCollection} from "../../firebase.js";
import isNil from "lodash/isNil";
import MenuDown from "vue-material-design-icons/MenuDown";
import MenuUp from "vue-material-design-icons/MenuUp";

export default {
  name: "AdminUnitsList",
  components: {
    MenuDown,
    MenuUp
  },
  data: () => ({
    currentSortDir: 'asc',
    shortlist: [],
    counts: [],
    unitShortlisters: [],
    document: {
      name: null,
    },
    currentSort: 'firstName',
    loading: true,
  }),
  methods: {
        isSorted(field, direction) {
      if (this.currentSort === field && this.currentSortDir === direction)
        return true;

      return false;
    },
    sort:function(s) {
      if(s === this.currentSort)
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';

      this.currentSort = s;
    },
      getProfilesCollection() {
        profilesCollection.onSnapshot((profiles) => {
          this.unitShortlisters = []
          profiles.forEach((profile) => {
            if (
              profile.data().unitShortlistIDs &&
              profile.data().unitShortlistIDs.length > 0                      
            ) {
              profile.data().unitShortlistIDs.map((unit) => {
                if (unit === this.$route.params.id)
                  this.unitShortlisters.push(profile.data())
              });
            }
          });
        });
      },
      getUnit(unitID) {
        unitsCollection
          .doc(unitID)
          .get()
          .then((doc) => {
            this.document.name = doc.data().name;
            this.document.status = doc.data().status;
            this.unitID = doc.id;
          });
      }
  },
  computed: {
    sortUsers: function() {
      let users = this.unitShortlisters
      return users.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },
  mounted() {
      if (!isNil(this.$route.params.id)) {
        const unitID = this.$route.params.id;
        this.getUnit(unitID);
      }
      this.getProfilesCollection();
    },
  }
</script>
<style >
.closeButtonPadding {
  padding-top: 25px;
}

</style>
