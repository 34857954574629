<template>
  <!-- Page Content -->
  <div>
    <header>
      <h1 class="text-3xl font-semibold tracking-wide">Unit Shortlists</h1>
      <p class="mt-2 text-md" style="margin-left: 2px">
        {{ totalUnits }} Units Shortlisted
      </p>
    </header>

    <!-- Unit Pending Table Content -->
    <table class="min-w-max w-full mt-5 table-auto">
      <!-- Unit Pending Table Content Header -->
      <thead>
      <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
        <th
          class="py-3 px-6 text-center"
          v-if="hasAccess('units', 'edit')"
        ></th>
        <th
          @click="sort('status')"
          class="heading-text"
          :class="{ sorting: currentSort === 'status' }"
        >
          Status
          <MenuDown
            v-if="isSorted('status', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('status', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('name')"
          class="heading-text"
          :class="{ sorting: currentSort === 'name' }"
        >
          Unit
          <MenuDown
            v-if="isSorted('name', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('name', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('price')"
          class="heading-text"
          :class="{ sorting: currentSort === 'price' }"
        >
          Price
          <MenuDown
            v-if="isSorted('price', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('price', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('counts')"
          class="heading-text"
          :class="{ sorting: currentSort === 'shortlisted' }"
        >
          # SHORTLISTS
          <MenuDown
            v-if="isSorted('counts', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('counts', 'asc')"
            class="inline-block absolute"
          />
        </th>
      </tr>
      </thead>

      <!-- Unit Pending Table Content Body -->
      <tbody class="text-gray-600 text-sm font-light" >
      <tr
        v-for="unit in sortedUnits"
        :key="unit.id"
        class="border-b border-gray-200 hover:bg-gray-100"
      >
        <td
          class="py-3 px-6 text-left whitespace-nowrap"
          v-if="hasAccess('units', 'edit')"
        >
            <div class="flex item-center justify-center">
            <router-link
              :to="{ name: 'admin-unit-shortlisters', params: { id: unit.id } }">
              <ViewIcon />
            </router-link>
          </div>
        </td>
        <td class="py-3 px-6 text-center">
            <span
              v-if="unit.status === 'available'"
              class="bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs"
            >
              Available
            </span>
          <span
            v-if="unit.status === 'pending' || unit.status === 'pendingUnitDetails' || unit.status === 'pendingPayment'"
            class="bg-gray-200 text-gray-600 py-1 px-3 rounded-full text-xs"
          >
              Pending
            </span>
          <span
            v-if="unit.status === 'reserved'"
            class="bg-blue-200 text-blue-600 py-1 px-3 rounded-full text-xs"
          >
              Reserved
            </span>

          <span
            v-if="unit.status === 'sold'"
            class="bg-red-200 text-red-600 py-1 px-3 rounded-full text-xs"
          >
              Sold
            </span>
        </td>

        <td class="py-3 px-6 text-center">
          {{ unit.name }}
        </td>
        <td class="py-3 px-6 text-center">
          {{ unit.price | prettyCurrency }}
        </td>
        <td
        class="py-3 px-6 text-center">
          {{ (counts[unit.id]) ? counts[unit.id] : 0 }}
        </td>
      </tr>
      </tbody>
    </table>

    <!-- Unit Pending Table Content Pagination -->
    <p class="pagination-container">
      <button
        v-if="currentPage != 1"
        @click="prevPage"
        class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
      >
        &lt; Prev
      </button>

      <button
        v-if="(this.currentPage*this.pageSize) < this.units.length"
        @click="nextPage"
        class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
      >
        Next &gt;
      </button>
    </p>
  </div>
</template>

<script>
import { unitsCollection, profilesCollection} from "../../firebase.js";
import isNil from "lodash/isNil";
import ViewIcon from "vue-material-design-icons/Eye";
import permissionsMixin from "@/mixins/permissions";
import {APP_TITLE, APP_ADMIN_PAGINATION} from "@/Constants";
import MenuDown from "vue-material-design-icons/MenuDown";
import MenuUp from "vue-material-design-icons/MenuUp";
import {reverse} from "lodash";

export default {
  name: "AdminUnitsList",
  mixins: [permissionsMixin],
  components: {
    ViewIcon,
    MenuDown,
    MenuUp
  },
  data: () => ({
    title: APP_TITLE,
    currentSort: 'price',
    currentSortDir: 'asc',
    pageSize: APP_ADMIN_PAGINATION,
    currentPage: 1,
    units: [],
    shortlist: [],
    counts: []
  }),
  created() {
    this.getShortlistCollection();
  },
  computed: {
    sortedUnits: function() {
      let units = this.units
      return units.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    totalUnits: function () {
      if (!isNil(this.units))
        return this.units.length;

      return 0;
    },
  },
  methods: {
    returnID(id)
    {
      alert(id);
    },
    getShortlistCollection() {
      profilesCollection.onSnapshot((profiles) => {
        this.shortlist = this.counts = []
        profiles.forEach((profile) => {
          if (
            profile.data().unitShortlistIDs &&
            profile.data().unitShortlistIDs.length > 0
          ) {
            profile.data().unitShortlistIDs.map((unit) => {
              this.shortlist.push(unit)
            });
          }
        });
        this.shortlist.map((x) => this.counts[x] = (this.counts[x] || 0) + 1 );
        this.getUnits("name", false);
      });
    },
    getUnits(orderBy, orderByReverse) {
      const keys = Object.keys(this.counts);
      unitsCollection
        .orderBy(orderBy)
        .onSnapshot((snap) => {
          let units = [];
          snap.forEach((doc) => {
            let unit = {
                ...doc.data(),
                ...{
                  id: doc.id,
                  counts: this.counts[doc.id] ? this.counts[doc.id] : 0
                }
            };
            if (keys.includes(doc.id)) {
              units.push(unit);
            }
        });
        if (orderByReverse)
          this.units = reverse(units);
        else
          this.units = units;

        this.componentLoading = false;
      });
    },
    isSorted(field, direction) {
      if (this.currentSort === field && this.currentSortDir === direction)
        return true;

      return false;
    },
    sort:function(s) {
      if(s === this.currentSort)
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';

      this.currentSort = s;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.units.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
  }
};
</script>

<style>
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 100%;
  }

  .sorting {
    background: #ffffff33;
  }

  .heading-text {
    cursor: pointer;
    text-align: center;
    padding: 0.75rem 1.5rem;
  }

  .heading-text:hover {
    opacity: .7;
    background: #fffff333;
  }
</style>